<template>
  <v-card  style="border-radius: 8px" class="shadow product_card"   @click="
          $emit('edit', {
            cindex: cindex,
            index: index,
          })
        ">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="d-flex gap-x-5 align-center">
            <div style="width: 130px ; height: 130px">
              <view-image :image="image"   style="border-radius: 0.5rem;" :height="130" :width="130"></view-image>
            </div>
            <div>
              <p class="font-semibold text-lg black--text  m-1 m-b-4"><span class="line-clamp-1">{{ name }}</span> </p>
              <p class="font-medium  m-1 p-0">Service:&nbsp; <span class="text-elepsis">{{ service }}</span> </p>
              <p class="font-medium  m-1 p-0">Price: <span class="text-elepsis">{{ price }} {{ currencyCode }}</span></p>
              <p class="font-medium  m-1 p-0">
                Tax:
                <span class="text-elepsis">{{
                    tax_type_id ? (tax_type_id == "1" ? "5% Tax" : "0% Tax") : ""
                  }}</span>
              </p>
            </div>
          </div>
        </v-col>

      </v-row>
    </v-card-text>
    <v-bottom-navigation light v-if="1==2">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="
          $emit('edit', {
            cindex: cindex,
            index: index,
          })
        "
      >
        <span>Edit</span>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        text
        @click="
          $emit('delete', {
            cindex: cindex,
            index: index,
          })
        "
      >
        <span>Delete</span>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn text>
        <span>View</span>
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-spacer></v-spacer> -->
    </v-bottom-navigation>
  </v-card>
</template>

<script>
// TODO:
export default {
  props: {
    name: { type: String, default: "Product name" },
    price: { default: "100" },
    id: { type: Number },
    index: { type: Number },
    cindex: { type: Number },
    image: { type: String, default: "" },
    service: { type: String, default: "NA" },
    tax_type_id: { type: Number, default: 1 },
  },
};
</script>
<style scoped>
.product_details {
  font-size: 30px;
}
.product_card {
  border: 1px solid rgba(17, 42, 70, 0) !important;
  cursor: pointer;
}

.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}
.product_card:hover{
  border: 1px solid rgba(17, 42, 70, 1) !important;
}
</style>