var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"shadow product_card",staticStyle:{"border-radius":"8px"},on:{"click":function($event){return _vm.$emit('edit', {
          cindex: _vm.cindex,
          index: _vm.index,
        })}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex gap-x-5 align-center"},[_c('div',{staticStyle:{"width":"130px","height":"130px"}},[_c('view-image',{staticStyle:{"border-radius":"0.5rem"},attrs:{"image":_vm.image,"height":130,"width":130}})],1),_c('div',[_c('p',{staticClass:"font-semibold text-lg black--text  m-1 m-b-4"},[_c('span',{staticClass:"line-clamp-1"},[_vm._v(_vm._s(_vm.name))])]),_c('p',{staticClass:"font-medium  m-1 p-0"},[_vm._v("Service: "),_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(_vm.service))])]),_c('p',{staticClass:"font-medium  m-1 p-0"},[_vm._v("Price: "),_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(_vm.price)+" "+_vm._s(_vm.currencyCode))])]),_c('p',{staticClass:"font-medium  m-1 p-0"},[_vm._v(" Tax: "),_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(_vm.tax_type_id ? (_vm.tax_type_id == "1" ? "5% Tax" : "0% Tax") : ""))])])])])])],1)],1),(1==2)?_c('v-bottom-navigation',{attrs:{"light":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('edit', {
          cindex: _vm.cindex,
          index: _vm.index,
        })}}},[_c('span',[_vm._v("Edit")]),_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('delete', {
          cindex: _vm.cindex,
          index: _vm.index,
        })}}},[_c('span',[_vm._v("Delete")]),_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-spacer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }