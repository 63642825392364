<template>
  <v-dialog persistent v-model="isEnableAddProduct"  width="40%">
    <v-form ref="product_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Add Product" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row dense justify="center" class="mt-6">
            <v-col md="4" class="pb-0" >
              <div style="height: 200px !important; width: 200px !important; margin: auto">
                <image-uploader
                    @upload="
                  (data) => {
                    product.image = data;
                  }
                "
                    @remove="
                  () => {
                    product.image = null;
                  }
                "
                    :image_path="product.image_path"
                    :height="200"
                    message-text=""
                    text="Product Picture"
                ></image-uploader>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col md="6">
              <label for="">Product Name*</label>
              <v-text-field
                  outlined
                  required
                  background-color="#fff"
                  v-model="product.name"
                  :rules="[(v) => !!v || 'Product name is required']"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label for="">Product Name  (Ar)</label>
              <v-text-field
                  outlined
                  background-color="#fff"
                  v-model="product.ar_name"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
              ></v-text-field>
            </v-col>


            <v-col md="6">
              <label for="">Product Description*</label>
              <v-textarea
                  outlined
                  rows="4"
                  background-color="#fff"
                  required
                  v-model="product.description"
                  :rules="[(v) => !!v || 'Product description is required']"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
              ></v-textarea>
            </v-col>
            <v-col md="6">
              <label for="">Product Description (Ar)</label>
              <v-textarea
                  outlined
                  rows="4"
                  background-color="#fff"
                  v-model="product.ar_description"
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="border-bottom mb-4 mt-4">
            <v-col>
              <p class="font-bold text-lg black-text p-0 m-0">Product Price</p>
            </v-col>
          </v-row>
          <v-row dense class="mt-2">
            <v-col md="4" >
              <label for="">Tax</label>
              <v-select
                v-model="product.tax_type_id"
                item-value="value"
                item-text="text"
                hint="Tax percentage"
                :items="taxTypes"
                @change="taxChange"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col md="4" >
              <label for="">Price (Pre Tax)*</label>
              <v-text-field
                outlined
                :prefix="currencyCode"
                background-color="#fff"
                required
                v-model="product.pre_tax_price"
                @change="calculateTaxVariation($event, 'pre')"
                :rules="[
                  (v) => {
                    if (v >= 0) {
                      return true;
                    }
                    return 'Price is required';
                  },
                ]"
                class="q-text-field shadow-0"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <label for="">Price (Post Tax)*</label>
              <v-text-field
                outlined
                :prefix="currencyCode"
                background-color="#fff"
                required
                v-model="product.total_price"
                @change="calculateTaxVariation($event, 'post')"
                :rules="[
                  (v) => {
                    if (v >= 0) {
                      return true;
                    }
                    return 'Price is required';
                  },
                ]"
                class="q-text-field shadow-0"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isEnableDctErp" class="mb-2">
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                outlined
                background-color="#fff"
                v-model="projectNumber"
                hide-details="auto"
                label="Project Number *"
                placeholder="Project Number"
                required
                :readonly="projectNumber?true:false"
                :rules="[
                  (v) =>
                    !!v || 'Project number is required',
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                outlined
                background-color="#fff"
                v-model="transactionType"
                hide-details="auto"
                label="Transaction Type*"
                placeholder="Transaction Type"
                required
                :readonly="transactionType?true:false"
                :rules="[
                  (v) =>
                    !!v || 'Transaction Type is required',
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                label="Task Name*"
                v-model="product.task_name"
                item-value="value"
                item-text="text"
                hint="Required Task Name"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Task name is required']"
                :items="taskNames"
                hide-details="auto"
                outlined
                background-color="#fff"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                label="GL Code*"
                v-model="product.gl_code"
                item-value="value"
                item-text="text"
                hint="Required GL Code"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'GL code is required']"
                :items="glCodes"
                hide-details="auto"
                outlined
                background-color="#fff"
              ></v-select>
            </v-col>
            
          </v-row>
          <!-- <v-row class="my-0">
            <v-col :lg="product.ticket_type == 'G' ? 6 : 12">
              <v-select
                label="Type*"
                required
                v-model="product.ticket_type"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :items="packageTypes"
                item-text="name"
                item-value="type"
                @change="$forceUpdate()"
                :rules="[(v) => !!v || 'Type is required']"
              ></v-select>
            </v-col>
            <v-col v-if="product.ticket_type == 'G'" lg="6">
              <v-text-field
                label="Participant count*"
                required
                outlined
                background-color="#fff"
                v-model="product.participant_count"
                :rules="[
                  (v) => !!v || 'Participant count',
                  (v) => !isNaN(v) || 'Participant count must be Number',
                ]"
              ></v-text-field>
            </v-col>
          </v-row> -->

          <v-row dense class="mt-4 border-bottom">
            <v-col>
              <p class="font-bold text-lg black-text p-0 m-0">Configuration</p>
            </v-col>
          </v-row>
          <v-row dense class="mt-2">
            <v-col md="4" class="">
              <v-switch
                label="Enable Inventory"
                v-model="product.inventory_enable"
                true-value="1"
                false-value="0"
                @change="$forceUpdate()"
              />
            </v-col>
            <v-col md="4" class="">
              <v-switch
                  label="Enable Online Booking"
                  v-model="product.enable_online_booking"
                  true-value="1"
                  false-value="0"
                  @change="$forceUpdate()"
              />

            </v-col>
            <v-col md="4" class="">
              <v-switch
                  label="Exclude from discounts"
                  v-model="product.benefit_excluded"
                  true-value="1"
                  false-value="0"
                  @change="$forceUpdate()"
              />

            </v-col>
          </v-row>


          <v-row dense v-if="product.inventory_enable == '1'" class="border-bottom">
            <v-col>
              <p class="font-bold text-lg black-text p-0 m-0">Inventory</p>
            </v-col>
          </v-row>


          <template v-if="product.inventory_enable == '1'">
            <v-row dense class="mt-4">

              <v-col md="6">
                <label for="">Inventory Quantity*</label>
                <v-text-field
                    v-model="product.quantity"
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    required
                    :rules="[
                      (v) => {
                        if (v && isNaN(v)) {
                          return 'Quantity must be number';
                        }
                        return true;
                      },
                    ]"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <label for="">Inventory period</label>
                <v-select
                    v-model="product.inventory_period"
                    item-text="name"
                    item-value="slug"
                    hint="Inventory period "
                    :items="[
                      { slug: 'D', name: 'Daily' },
                      { slug: 'T', name: 'Per Timeslot' },
                    ]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    hide-details="auto"
                    dense
                    class="q-autocomplete shadow-0"
                    background-color="#fff"

                ></v-select>
              </v-col>
              <v-col md="12">
                <v-switch
                    label="Specific Day"
                    v-model="product.is_enable_specific_day"
                    true-value="1"
                    false-value="0"
                    @change="$forceUpdate()"
                />
              </v-col>
            </v-row>

            <template v-if="product.is_enable_specific_day == '1'">

              <v-row dense class=" border-bottom mb-4 pb-4"  v-for="(rules, rl) in product.inventory_rules"
                     :key="`${rl}`">
                <v-col lg="4">
                  <label for="">Days Applicable</label>
                  <v-select
                      :items="weekdays"
                      item-text="name"
                      item-value="bit_value"
                      multiple
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      validate-on-blur
                      :rules="[(v) => v.length > 0 || 'Days is required']"
                      v-model="rules.weekdays"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                  >
                    <template
                        v-if="weekdays.length == rules.weekdays.length"
                        v-slot:selection="{ index }"
                    >
                      <span v-if="index == 0">All Days</span>
                    </template>
                    <template v-else v-slot:selection="{ item, index }">
                      <span v-if="index == 0">{{ item.name }}</span>
                      <span v-if="index == 1">, {{ item.name }}</span>
                      <span v-if="index == 2">, ...</span>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggle(rl)">
                        <v-list-item-action>
                          <v-icon
                              :color="
                                    rules.weekdays.length > 0
                                      ? 'indigo darken-4'
                                      : ''
                                  "
                          >{{ getIcon(rl) }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>All Days</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col lg="3">
                  <label for="">From</label>
                  <v-select
                      :items="timings.slice(0, timings.length - 1)"
                      item-text="text"
                      item-value="value"
                      :rules="[(v) => !!v || 'From time is required']"
                      v-model="rules.start_time"
                      required
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col lg="3">
                  <label for="">To</label>
                  <v-select
                      :items="timings.slice(1)"
                      item-text="text"
                      item-value="value"
                      :rules="[(v) => !!v || 'From time is required']"
                      v-model="rules.end_time"
                      required
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                  ></v-select
                  ></v-col>
                <v-col lg="2">
                  <v-btn
                      class="mt-6"
                      color="red"
                      fab
                      x-small
                      dark
                      @click="deleteInventoryRule(rl)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

            </template>
            <v-row dense v-if="product.is_enable_specific_day == '1'">
              <p  @click="addInventoryRule()" class="text-neon text-base font-bold text-underline pointer">+Add More Days</p>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="red" class="ma-2 " @click="deleteProduct(product.id , product.category_id)" v-if="product && product.id"
          >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2 "
            >Close</v-btn
          >
          <v-btn text class="ma-2 white--text blue-color" @click="addProduct"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>
<script>
import constants from "@/utils/constants";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";

export default {
  components: {ImageUploader, SvgIcon},
  props: {
    name: { type: String, default: "" },
    ar_name: { type: String, default: "" },
    description: { type: String, default: "" },
    ar_description: { type: String, default: "" },
    id: { type: Number },
    image_path: { type: String },
    benefit_excluded: { type: Number, default: 0 },
    inventory_enable: { type: Number, default: 0 },
    enable_online_booking: { type: Number, default: 0 },
    tax_type_id: { type: Number, default: null },
    price: { type: Number, default: 0 },
    total_price: { type: Number, default: 0 },
    category_id: { type: Number, default: null },
    isEnableAddProduct: { type: Boolean, default: false },
    inventory_period: { type: String, default: "D" },
    quantity: { type: Number, default: 0 },
    participant_count: { type: Number },
    ticket_type: { type: String },
    rules: { type: Array, default: () => [] },
    is_enable_specific_day: { type: Number, default: 0 },
    isEnableDctErp: { type: Boolean, default: false },
    projectNumber:  { type: String, default: null },
    transactionType: { type: String, default: null },
    task_name: { type: String, default: null },
    gl_code:  { type: String,default: null }
  },
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      product: {
        quantity: 0,
        benefit_excluded: "0",
        product_type: "Facility",
        inventory_rules: [
          {
            weekdays: [],
            start_time: "00:00:00",
            end_time: "23:59:59",
          },
        ],
      },
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      timings: constants.TIMINGS,
      taskNames: constants.TASK_NAMES,
      glCodes: constants.GL_CODES,
    };
  },
  computed: {
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  watch: {
    isEnableAddProduct: {
      immediate: true,
      handler(val) {
        console.log(this.$props);
        if (val) {
          if (this.id > 0) {
            this.product.id = this.id;
            this.product.name = this.name;
            this.product.ar_name = this.ar_name;
            this.product.description = this.description;
            this.product.ar_description = this.ar_description;
            this.product.benefit_excluded = this.benefit_excluded.toString();
            this.product.price = this.price;
            this.product.pre_tax_price = this.price;
            this.product.image_path = this.image_path;
            this.product.total_price = this.total_price;
            this.product.inventory_enable = this.inventory_enable.toString();
            this.product.enable_online_booking = this.enable_online_booking.toString();
            this.product.inventory_period = this.inventory_period;
            this.product.quantity = this.quantity;
            this.product.ticket_type = this.ticket_type;
            this.product.participant_count = this.participant_count;
            if (this.rules.length) {
              this.product.is_enable_specific_day = this.is_enable_specific_day.toString();
              this.product.inventory_rules = this.rules;
            }
          } else {
            this.product = {
              inventory_rules: [
                {
                  weekdays: [],
                  start_time: "00:00:00",
                  end_time: "23:59:59",
                },
              ],
            };
            this.product.benefit_excluded = "0";
            this.product.inventory_period = "D";
            this.product.inventory_enable = "0";
            this.product.enable_online_booking = "1";
          }
          this.product.product_type = "Facility";
          this.product.category_id = this.category_id;
          this.product.tax_type_id = this.tax_type_id;
          this.product.task_name = this.task_name;
          this.product.gl_code = this.gl_code;
          console.log("Popup")
          console.log(this.product);
          this.$forceUpdate();
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save");
    },
    deleteProduct(productId , categoryId){
      this.$emit('deleteProduct',productId , categoryId)
    },
    addProduct() {
      if (!this.$refs.product_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      var formData = new FormData();
      for (let key in this.product) {
        if (
          this.product[key] != null &&
          key != "id" &&
          key != "inventory_rules"
        ) {
          formData.append(key, this.product[key]);
        }
        if (this.product[key] != null && key == "inventory_rules") {
          this.product.inventory_rules.forEach((el, index) => {
            formData.append(`inventory_rules[${index}][weekdays]`, el.weekdays);
            formData.append(
              `inventory_rules[${index}][start_time]`,
              el.start_time
            );
            formData.append(`inventory_rules[${index}][end_time]`, el.end_time);
          });
        }
      }
      if (this.isEnableDctErp) {
        formData.append('project_no', this.projectNumber);
        formData.append('transaction_type', this.transactionType);
      }

      this.$http({
        method: "post",
        data: formData,
        url: `venues/facilities/categories/products${
          this.product.id ? "/" + this.product.id : ""
        }`,

        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.save();
            this.showSuccess(
              "Product successfully " + this.product.id ? "updated" : "created."
            );
            this.product = {};
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    calculateTaxVariation(amount, type) {
      let taxTypeId = this.product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
          .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.product.price = priceData.price;
        this.product.pre_tax_price = priceData.price.toFixed(2);
        this.product.total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },
    taxChange() {
      if (this.product.price) {
        this.calculateTaxVariation(this.product.price, "pre");
      } else if (this.product.total_price) {
        this.calculateTaxVariation(this.product.total_price, "post");
      }
    },

    getIcon(index) {
      let icon = "mdi-checkbox-blank-outline";
      if (!this.product.inventory_rules[index]) {
        this.product.inventory_rules[index]["weekdays"] = [];
      }
      if (
        this.product.inventory_rules[index] &&
        this.product.inventory_rules[index].weekdays.length ==
          this.weekdays.length
      ) {
        icon = "mdi-close-box";
      }
      if (
        this.product.inventory_rules[index] &&
        this.product.inventory_rules[index].length > 0 &&
        this.product.inventory_rules[index].length != this.weekdays.length
      )
        icon = "mdi-minus-box";

      return icon;
    },

    toggle(index) {
      this.$nextTick(() => {
        if (
          this.product.inventory_rules[index].weekdays.length ==
          this.weekdays.length
        ) {
          this.product.inventory_rules[index].weekdays = [];
        } else {
          this.product.inventory_rules[index].weekdays = this.weekdays.map(
            (item) => item.bit_value
          );
        }
      });
      this.$forceUpdate();
    },

    addInventoryRule() {
      this.product.inventory_rules.push({
        weekdays: [],
        start_time: "00:00:00",
        end_time: "23:59:59",
      });
    },

    deleteInventoryRule(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this service?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "rules",
      };
    },

    confirmActions(data) {
      if (data.type == "rules") {
        this.product.inventory_rules.splice(data.id, 1);
      }
      if (this.product.inventory_rules.length == 0) {
        this.product.inventory_rules.push({
          weekdays: [],
          start_time: "00:00:00",
          end_time: "23:59:59",
        });
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped>
.v-input--switch{
  .v-input__slot{
    //flex-direction: row-reverse;
  }
}

</style>
